import React from 'react';

import { ArticleView } from 'components/irminabobowska/common/article/view';

import p from 'images/irminabobowska/p.svg';

import './view.scss';

export const CooperationView = () => (
    <ArticleView
        name="wspolpraca"
        headline="Jak wygląda praca ze mną?"
        description={(
            <p>
                <strong>Pierwsze do 3 spotkań mają charakter konsultacyjny.</strong>
                {' '}
                Dają Ci możliwość zdecydowania czy jestem dla Ciebie dobrym psychoterapeutą, a mnie — czy mogę zając się tym, z czym przychodzisz. Ustalamy cel psychoterapii i po czym poznasz na ostatniej sesji, że go osiągnąłeś(–łaś).
                <br />
                <br />
                <strong>Mój sposób pracy to sesje raz w tygodniu bądz dwa. Swoją pracę superwizuję u certyfikowanych superwizorów z Polskiego Towarzystwa Psychologicznego i Polskiego Towarzystwa Psychoterapii Psychoanalitycznej.</strong>
                {' '}
                Mam doświadczenie w pracy z osobami z zaburzeniami nastroju, jedzenia, zachowania, odczuwających lęki i napięcia, a także tymi które uznały, że potrzebują wprowadzić zmiany, mające istotnie poprawić jakość ich życia i przyczynić się do poczucia większej satysfakcji w związkach.
                <br />
                <br />
                Jestem osobą ciepłą i otwartą, nieustannie gotową nieść pomoc osobom w potrzebie.
                {' '}
                <strong>Posiadam kilkunastoletnie doświadczenie pracy klinicznej w publicznej służbie zdrowia z pacjentami oddziału psychiatrycznego, neurologicznego, leczenia zaburzeń afektywnych oraz zaburzeń nerwicowych.</strong>
                {' '}
                Pracuję również z klientami w ramach prywatnej praktyki i organizuję szkolenia rozwojowe.
                <br />
                <br />
                Nagrodą jest wdzięczność pacjentów i satysfakcja z dobrze wykonanej pracy.
                <br />
                <br />
                <strong>W życiu kieruję się takimi wartościami, jak wolność, szczodrość, kreatywność, współczucie i lekkość.</strong>
            </p>
        )}
        initial={p}
        aside={(
            <>
                <p className="cooperation__headline">Szkolenia:</p>
                <ul className="cooperation__list">
                    <li>
                        specjalizacja w dziedzinie „Psychologia kliniczna zaburzeń psychicznych” w SWPS i Centrum Medycznym Kształcenia Podyplomowego,
                    </li>
                    <li>
                        seminaria teoretyczno-kliniczne prowadzone przez psychoanalityków szkoleniowych: Agnieszkę Humięcką, Katarzynę Kędziora-Kummer, Stefanię Czerniecką-Tomkowiak i Dawida Bieńkowskiego,
                    </li>
                    <li>
                        całościowe szkolenie w psychoterapii w Śląskiej Szkole Psychoterapii w Ośrodku Leczenia Nerwic i Zaburzeń Odżywiania „Dąbrówka”,
                    </li>
                    <li>
                        szkolenie z Terapii Ericksonowskiej (Curriculum) oraz z Terapii Stanów Ego w Polskim Instytucie Ericksonowskim z nauczycielami polskimi i zagranicznymi,
                    </li>
                    <li>
                        szkolenie w neuropsychologii w Sopockim Centrum Terapii Poznawczo-Bahawioralnej,
                    </li>
                    <li>
                        szkolenia w Laboratorium Psychoedukacji.
                    </li>
                </ul>
                <br />
                Pracuję z osobami młodymi i dorosłymi.
                <br />
                <br />
                Pracuje online i w gabinecie w Poznaniu.
                <br />
                <br />
                Przyjmuję od poniedziałku do piątku w godzinach indywidualnie ustalonych z klientem.
            </>
        )}
    />
);