import React from 'react';

import { SectionView } from 'components/irminabobowska/common/section/view';

import facebook from 'images/irminabobowska/facebook.png';

import './view.scss';

export const ContactView = () => (
    <SectionView name="kontakt" headline="Jak się ze mną skontaktować?">
        <h3 className="contact__headline">
            Przez e-mail
        </h3>
        <a href="mailto:gabinetirminabobowska@gmail.com" target="_blank" rel="noreferrer">
            gabinetirminabobowska@gmail.com
        </a>
        <h3 className="contact__headline">
            Przez telefon
        </h3>
        <a href="tel:+48600993987" target="_blank" rel="noreferrer">
            +48 600 993 987
        </a>
        <h3 className="contact__headline">
            Przez social media
        </h3>
        <div className="contact__container">
            <a
                className="contact__link"
                href="https://www.facebook.com/Irmina-Bobowska-Gabinet-Psychoterapii-i-Rozwoju-Osobistego-111378613742749"
                target="_blank"
                rel="noreferrer"
            >
                <img className="contact__icon" src={facebook} alt="facebook" />
            </a>
        </div>
        <h3 className="contact__headline">
            Osobiście
        </h3>
        <p>
            Sienkiewicza 7/4
            <br />
            60-816 Poznań
        </p>
    </SectionView>
);