import React from 'react';
import PropTypes from 'prop-types';

import { SectionView } from 'components/irminabobowska/common/section/view';

import './view.scss';

export const AdvantagesView = ({ advantages }) => (
    <SectionView
        isColored
        name="korzysci"
        headline="Co zyskasz, przychodząc do mnie?"
    >
        <div className="advantages__container">
            {
                advantages.map(({ number, content }, index) => (
                    <div className="advantages__advantage" key={index}>
                        <p className="advantage__number">
                            {number}
                        </p>
                        {content}
                    </div>
                ))
            }
        </div>
    </SectionView>
);

AdvantagesView.propTypes = {
    advantages: PropTypes.instanceOf(Array).isRequired
};