import React from 'react';

import './view.scss';

export const ButtonView = () => (
    <a
        className="button"
        href="https://www.znanylekarz.pl/irmina-bobowska"
        target="_blank"
        rel="noreferrer"
    >
        Zgłoś się do mnie
    </a>
);