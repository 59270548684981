import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './view.scss';

export const SectionView = ({
    isColored,
    name,
    headline,
    children
}) => (
    <section
        className={classnames('section', {
            'section--colored': isColored
        })}
        id={name}
    >
        <div className="section__container">
            <h2 className="section__headline">
                {headline}
            </h2>
            {children}
        </div>
    </section>
);

SectionView.defaultProps = {
    isColored: false
};

SectionView.propTypes = {
    isColored: PropTypes.bool,
    name: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};