import React from 'react';

import { AdvantagesView } from './view';

export const Advantages = () => {
    const advantages = [
        {
            number: '01',
            content: 'Możliwość wnikliwej, stabilnej, empatycznej i twórczej pracy nad wszystkim, co trudne i bolesne w życiu.'
        }, {
            number: '02',
            content: 'Ucząc się mierzyć z niechcianymi uczuciami i problemami, rozwiniesz wewnętrzną siłę i osobisty potencjał.'
        }, {
            number: '03',
            content: 'Całkowicie zredukujesz lub przynajmniej zmniejszysz odczuwane lęki.'
        }, {
            number: '04',
            content: 'Wydobędziesz się z emocjonalnego zamieszania czy życiowego impasu.'
        }, {
            number: '05',
            content: 'Psychoterapia psychodynamiczna zakłada, że przeszłość to prolog. Poznasz przyczyny swoich aktualnych problemów.'
        }, {
            number: '06',
            content: 'Poznasz techniki i strategie odpowiednie tylko dla Ciebie — bo jesteś jedyny(–na) i niepowtarzalny(–na).'
        }, {
            number: '07',
            content: 'Rozjaśnisz miejsca, myśli i uczucia, których dotąd unikałeś(–łaś).'
        }, {
            number: '08',
            content: 'Poznasz powtarzające się schematy i wzorce.'
        }
    ];

    return (<AdvantagesView advantages={advantages} />);
};