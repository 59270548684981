import React from 'react';

import { ArticleView } from 'components/irminabobowska/common/article/view';

import c from 'images/irminabobowska/c.svg';

import './view.scss';

export const AboutMeView = () => (
    <ArticleView
        name="o-mnie"
        headline="Słowem wstępu"
        description={(
            <p>
                Czujesz się, jakbyś wpadł(a) do studni i znikąd pomocy? Nie wiesz, co się z Tobą dzieje, poza tym, że coś niedobrego?
                {' '}
                <strong>Zależy Ci na długofalowych efektach i wypracowaniu nowych form działania?</strong>
                {' '}
                Trafiłeś(–łaś) we właściwe miejsce.
                <br />
                <br />
                Niektóre problemy tkwią głęboko w nas samych. Potrzeba czasu i rozmowy, by odnaleźć ich przyczynę. Niełatwo jest zmierzyć się z przeszłością i z tkwiącymi w niej schematami. To droga, której nie warto pokonywać samemu. Pomogę Ci krok po kroku przeanalizować swoje zachowania i znaleźć sposób na powrót do wewnętrznej harmonii.
                {' '}
                <strong>Wypróbuj terapię psychodynamiczną, która łączy w sobie metody psychoanalizy z odkryciami współczesnej psychologii.</strong>
                {' '}
                Poprzez rozmowę i analizę słów, zachowań i marzeń sennych wspólnie dotrzemy do źródła Twoich kłopotów.
                <br />
                <br />
                Nazywam się Irmina Bobowska.
                {' '}
                <strong>Jestem dyplomowanym psychologiem, specjalistką psychologii klinicznej i psychoterapeutką.</strong>
                {' '}
                Kilkunastoletnie doświadczenie pracy z pacjentami o różnego typu zaburzeniach pozwoliło mi się przekonać, że nigdy nie wolno tracić nadziei. Jeśli tylko chcesz, możesz zmienić swoje życie — z radością pomogę Ci przejść przez ten proces. Wówczas pokażę Ci, że można nauczyć się lepiej radzić sobie ze sobą i z innymi ludźmi.
                {' '}
                <strong>Psychoterapia stwarza możliwość stabilnej, wnikliwej i empatycznej pracy nad tym, co od prawdy oddziela i pomaga w docieraniu do wewnętrznego bogactwa.</strong>
                <br />
                <br />
                Zapraszam do skorzystania z mojej wiedzy, umiejętności i doświadczenia w Gabinecie.
            </p>
        )}
        initial={c}
        aside={(
            <>
                <p className="about-me__headline">W czym mogę Ci pomóc:</p>
                <ul className="about-me__list">
                    <li>
                        zaburzenia lękowe,
                    </li>
                    <li>
                        stany depresyjne,
                    </li>
                    <li>
                        nerwicę,
                    </li>
                    <li>
                        zaburzenia odżywiania,
                    </li>
                    <li>
                        stres pourazowy,
                    </li>
                    <li>
                        problemy w relacjach,
                    </li>
                    <li>
                        zaburzenia osobowości,
                    </li>
                    <li>
                        kryzysy rozwojowe i emocjonalne,
                    </li>
                    <li>
                        problemy adaptacyjne,
                    </li>
                    <li>
                        zaburzenia psychosomatyczne,
                    </li>
                    <li>
                        rozumienie świata wewnętrznego,
                    </li>
                    <li>
                        inne problemy natury psychicznej.
                    </li>
                </ul>
            </>
        )}
    />
);