import React from 'react';

import './view.scss';

export const FooterView = () => (
    <footer className="footer">
        &lt;/&gt; with &lt;3 by
        {' '}
        <a href="https://www.spacecreations.pl" target="_blank" rel="noreferrer">spacecreations</a>
    </footer>
);