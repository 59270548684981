import React from 'react';
import PropTypes from 'prop-types';

import { SectionView } from 'components/irminabobowska/common/section/view';

import './view.scss';

export const ApproachView = ({ traits }) => (
    <SectionView
        name="podejscie"
        headline="Jakie jest moje podejście do pracy?"
    >
        <div className="approach__container">
            {
                traits.map(({ icon, headline, description, color }, index) => (
                    <div className="approach__trait" style={{ backgroundColor: color }} key={index}>
                        <img className="trait__icon" src={icon} alt="ikona" />
                        <h3 className="trait__headline">{headline}</h3>
                        {description}
                    </div>
                ))
            }
        </div>
    </SectionView>
);

ApproachView.propTypes = {
    traits: PropTypes.instanceOf(Array).isRequired
};