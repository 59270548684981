import React from 'react';
import PropTypes from 'prop-types';

import { SectionView } from 'components/irminabobowska/common/section/view';
import { ButtonView } from 'components/irminabobowska/common/button/view';

import './view.scss';

export const ArticleView = ({
    name,
    headline,
    description,
    initial,
    aside
}) => (
    <SectionView
        name={name}
        headline={headline}
    >
        <article className="article">
            <div className="article__content">
                <img className="article__initial" src={initial} alt="iniciał" />
                {description}
            </div>
            <aside className="article__aside">
                <div className="aside__content">
                    {aside}
                </div>
                <ButtonView />
            </aside>
        </article>
    </SectionView>
);

ArticleView.propTypes = {
    name: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    initial: PropTypes.string.isRequired,
    aside: PropTypes.node.isRequired
};