import React from 'react';

import { ButtonView } from 'components/irminabobowska/common/button/view';

import irminaBobowska from 'images/irminabobowska/irmina-bobowska.svg';
import armchair from 'images/irminabobowska/armchair.png';

import './view.scss';

export const HeaderView = () => (
    <header className="header">
        <div className="header__container">
            <nav className="header__navigation">
                <ul className="navigation__list">
                    <li className="navigation__item">
                        <a className="navigation__link" href="#kontakt">
                            <img className="navigation__logo" src={irminaBobowska} alt="irmina bobowska" />
                        </a>
                    </li>
                    <li className="navigation__item">
                        <a className="navigation__link" href="#o-mnie">
                            O mnie
                        </a>
                    </li>
                    <li className="navigation__item">
                        <a className="navigation__link" href="#podejscie">
                            Podejście
                        </a>
                    </li>
                    <li className="navigation__item">
                        <a className="navigation__link" href="#wspolpraca">
                            Współpraca
                        </a>
                    </li>
                    <li className="navigation__item">
                        <a className="navigation__link" href="#korzysci">
                            Korzyści
                        </a>
                    </li>
                    <li className="navigation__item">
                        <a className="navigation__link" href="#kontakt">
                            Kontakt
                        </a>
                    </li>
                </ul>
            </nav>
            <div className="header__banner">
                <h1 className="banner__headline">
                    Jestem z Tobą,
                    <br />
                    by umożliwić Ci
                    <br />
                    powrót do siebie
                </h1>
                <ButtonView />
                <div className="banner__background" />
                <img className="banner__image" src={armchair} alt="fotel" />
            </div>
        </div>
    </header>
);