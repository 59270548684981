import React from 'react';
import { Helmet } from 'react-helmet';

import { HeaderView } from 'components/irminabobowska/header/view';
import { AboutMeView } from 'components/irminabobowska/about-me/view';
import { Approach } from 'components/irminabobowska/approach';
import { CooperationView } from 'components/irminabobowska/cooperation/view';
import { Advantages } from 'components/irminabobowska/advantages';
import { ContactView } from 'components/irminabobowska/contact/view';
import { FooterView } from 'components/irminabobowska/footer/view';

import { url, title, description } from 'configurations/irminabobowska';

// import openGraph from 'images/irminabobowska/open-graph.png';

import 'styles/irminabobowska/fonts.scss';
import 'styles/irminabobowska/normalize.scss';

const Index = () => (
    <>
        <Helmet>
            <title>
                {title}
            </title>
            <meta name="description" content={description} />
        
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:locale" content="pl_PL" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:image" content={openGraph} /> */}
        </Helmet>
        <main className="index">
            <HeaderView />
            <AboutMeView />
            <Approach />
            <CooperationView />
            <Advantages />
            <ContactView />
            <FooterView />
        </main>
    </>
);

export default Index;