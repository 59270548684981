import React from 'react';

import conversation from 'images/irminabobowska/conversation.svg';
import support from 'images/irminabobowska/support.svg';
import science from 'images/irminabobowska/science.svg';

import { ApproachView } from './view';

export const Approach = () => {
    const traits = [
        {
            icon: conversation,
            headline: 'Mam naturę słuchacza i ludzie chętnie powierzają mi swoje problemy.',
            description: 'To pewnie dlatego zostałam psychoterapeutką. Lubię pomagać w docieraniu do sedna własnej świadomości. W stawaniu się coraz bardziej sobą. Dostrzegam powiązania w pozornie nieuporządkowanym świecie wyobrażeń, wspomnień i snów. Otwierając się przede mną, masz szansę powrócić bogatszy(–sza). O co? O odpowiedzi na pytania, których sam(a) byś sobie nie zadał(a).',
            color: '#86d0c3'
        }, {
            icon: support,
            headline: 'Nie obawiaj się: jestem, aby Ci pomóc.',
            description: 'Każdego pacjenta traktuję indywidualnie, szanując jego autonomię, światopogląd i potrzeby. Zapewniam dyskrecję, życzliwą atmosferę i profesjonalizm. Wysłuchuję, ale nie oceniam. Dbając o dobro każdego, kto do mnie przychodzi, respektuję standardy zawodowe i etyczne wyznaczone przez Sekcję Naukową Psychoterapii Polskiego Towarzystwa Psychiatrycznego i Kodeks Etyczno–Zawodowy Psychologa Polskiego Towarzystwa Psychologicznego.',
            color: '#e5e565'
        }, {
            icon: science,
            headline: 'W mojej pracy klinicznej stosuję metody oparte na podejściu psychodynamicznym.',
            description: 'Źródłem tej koncepcji są ustalenia różnych szkół psychoanalizy. Czerpie ona z bogactwa nauk psychologicznych począwszy od Freuda, który odkrył znaczenie podświadomości, poprzez teorię relacji z obiektem po psychologię ego i psychologię self Heinza Kohuta. Łączy w sobie najważniejsze odkrycia psychoterapii — można powiedzieć, oświetla osobistą historię pacjenta z różnych stron. Pozwala zrozumieć mechanizmy powstawania, przebiegu i leczenia zaburzeń psychicznych.',
            color: '#e1c5e2'
        }
    ];

    return (<ApproachView traits={traits} />);
};